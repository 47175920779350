import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import '../../components/antd/less/antMotionStyle.less'
import pic1 from './../../images/ai2.jpg'
import pic2 from './../../images/ai3.jpg'
import pic3 from './../../images/ai4.jpg'
import pic4 from './../../images/ai5.jpg'

export default class TraceSource extends React.PureComponent {
  render() {
    const {
      data: { strapi = {} },
    } = this.props
    const { products, productGroups = [] } = strapi

    return (
      <Layout
        curIndex={1}
        products={products}
        productGroups={productGroups}
        showFooter={true}
      >
        <SEO title='区块链+AI溯源技术' />
        <div className='core-tech-wrap texture-recognation-wrap'>
          <div className='main-img'></div>
          <div className='main-con'>
            <h2>三级溯源概述</h2>
            <p className='description-text no-indent'>
              <h3 style={{ fontWeight: 'bold' }}>
                易见纹语科技的基于区块链和人工智能的溯源技术，从三个层面打造了可信的溯源体系。
              </h3>
              <br />
              (1)贯穿产品全生命周期的区块链存证。易见纹语自有知识产权的“茶纹链”是网信办备案的用于农业的区块链技术。我们将农产品从种植养殖，到生产加工、仓储、销售流通过程中产生的全部资产信息、权责信息和活动信息在区块链上进行存证，从而实现数字世界中的信息的真实性、可靠性、不可篡改性。
              <br />
              <br />
              (2)从产品加工开始的物体本体纹录识别技术。该技术采用国际领先的人工智能技术，通过识别被溯源物体本体的纹理，进行产品的身份确认。从而实现物理世界中被溯源产品不可能被篡改，也不可能被仿冒。
              <br />
              <br />
              (3)人工智能视频分析技术。在产品的种植养殖、生产加工、仓储等环节，通过人工智能技术自动捕捉到人员或机器参与的种植生产活动、出入库活动等，并自动剪辑成短视频，哈希上链。消费者可以方便的通过溯源软件查看自己购买的产品的视频存证信息，实现了可视化溯源。
            </p>

            <div className='trace-source' style={{ marginTop: '5rem' }}>
              <img src={pic1} />
            </div>
          </div>
          <div className='core-tech-wrap solution-wrap'>
            <img src={pic2} />
          </div>
          <div className='main-con'>
            <h2>纹理识别技术概述</h2>
            <p className='description-text'>
              <br />
              <p className='has-indent'>
                易见纹语科技采用自有知识产权的国际领先的人工智能纹理识别技术，对被溯源产品本身的天然纹理（如：茶饼、火腿等），或工业
                合成纹理（如：橡胶、钢缆等）进行识别，在生产阶段为每件产品建立身份信息.该身份信息具有唯一性，并将伴随该产品的整个生命周
                期，不可能被篡改。
              </p>
              <br />
              <p className='has-indent'>
                易见纹语的人工智能纹理识别算法能从产品图片中随机选取近千个特征点，并生成高维矢量矩阵，记录该产品的纹理特征，并上传到
                区块链存证。这个算法经过长时间的数百万样本的训练，已经非常强大。即使被拍摄产品处于不同的光场，或者拍摄环境中有其它元素*
                干扰，或者产品因长期存放出现了变色、变蓬松等情况,甚至在产品部分缺失的情况下,算法仍能非常准确的从数以亿计的产品图片准确
                的定位到它。
              </p>
            </p>

            <div className='trace-source' style={{ marginTop: '5rem' }}>
              <img src={pic3} />
            </div>
          </div>
          <div className='core-tech-wrap solution-wrap'>
            <img src={pic4} />
          </div>
        </div>
      </Layout>
    )
  }
}
export const query = graphql`
  query {
    strapi {
      products {
        id
        title
        product_group {
          code
          name
        }
        type
      }
      productGroups {
        id
        code
        name
        type
      }
    }
  }
`
